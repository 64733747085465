"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var NotationsTable = function (_a) {
    var topNumbers = _a.topNumbers, bottomNumbers = _a.bottomNumbers, user = _a.user, formatNotation = _a.formatNotation;
    var getMaxParams = function (numbers) {
        return Math.max.apply(Math, __spreadArray([0], numbers.map(function (num) { var _a; return ((_a = user.notations[num]) === null || _a === void 0 ? void 0 : _a.length) || 0; }), false));
    };
    var topMaxParams = getMaxParams(topNumbers);
    var bottomMaxParams = getMaxParams(bottomNumbers);
    return ((0, jsx_runtime_1.jsxs)("tbody", { children: [__spreadArray([], Array(topMaxParams), true).map(function (_, index) { return ((0, jsx_runtime_1.jsx)("tr", { children: topNumbers.map(function (num) {
                    var _a;
                    return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: formatNotation((_a = user.notations[num]) === null || _a === void 0 ? void 0 : _a[index]) }, "".concat(num, "-top").concat(index + 1)));
                }) }, "top-".concat(index))); }), (0, jsx_runtime_1.jsx)("tr", { children: topNumbers.map(function (num) { return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: num }, "".concat(num, "-number-top"))); }) }), (0, jsx_runtime_1.jsx)("tr", { children: bottomNumbers.map(function (num) { return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: num }, "".concat(num, "-number-bottom"))); }) }), __spreadArray([], Array(bottomMaxParams), true).map(function (_, index) { return ((0, jsx_runtime_1.jsx)("tr", { children: bottomNumbers.map(function (num) {
                    var _a;
                    return ((0, jsx_runtime_1.jsx)("td", { className: "table__item", children: formatNotation((_a = user.notations[num]) === null || _a === void 0 ? void 0 : _a[index]) }, "".concat(num, "-bottom").concat(index + 1)));
                }) }, "bottom-".concat(index))); })] }));
};
exports.default = NotationsTable;
