"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_select_1 = __importStar(require("react-select"));
var MultiSelectWithInitialLabel = function (_a) {
    var _b;
    var name = _a.name, options = _a.options, initialLabel = _a.initialLabel, onUpdate = _a.onUpdate, _c = _a.selectedValues, selectedValues = _c === void 0 ? {} : _c;
    var _d = (0, react_1.useState)((_b = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues[name]) !== null && _b !== void 0 ? _b : []), selectedOptions = _d[0], setSelectedOptions = _d[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues[name]) {
            setSelectedOptions((_a = selectedValues[name]) !== null && _a !== void 0 ? _a : []);
        }
    }, [selectedValues, name]);
    var CustomOption = function (props) {
        return ((0, jsx_runtime_1.jsx)(react_select_1.components.Option, __assign({}, props, { children: (0, jsx_runtime_1.jsxs)("div", { style: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    columnGap: '0.5rem'
                }, children: [(0, jsx_runtime_1.jsx)("span", { children: props.data.label }), (0, jsx_runtime_1.jsx)("input", { type: "checkbox", checked: selectedOptions.includes(props.data.value), readOnly: true, style: { maxWidth: 'max-content' } })] }) })));
    };
    return ((0, jsx_runtime_1.jsx)(react_select_1.default, { options: options.map(function (value) { return ({ value: value, label: value }); }), isMulti: true, placeholder: selectedOptions.length === 0 ? initialLabel : initialLabel, closeMenuOnSelect: false, hideSelectedOptions: false, components: { Option: CustomOption }, inputValue: '', controlShouldRenderValue: false, onChange: function (selected) {
            var selectedValues = selected.map(function (opt) { return opt.value; });
            if (selectedValues.length <= 5) {
                setSelectedOptions(selectedValues);
                onUpdate(name, selectedValues);
            }
        }, value: selectedOptions.map(function (value) { return ({ value: value, label: value }); }), styles: {
            control: function (provided) { return (__assign(__assign({}, provided), { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' })); },
            menu: function (provided) { return (__assign(__assign({}, provided), { width: '300px' })); },
            menuList: function (provided) { return (__assign(__assign({}, provided), { maxHeight: '300px' })); },
            option: function (provided, state) { return (__assign(__assign({}, provided), { backgroundColor: selectedOptions.includes(state.data.value)
                    ? '#d3f9d8'
                    : 'white', color: selectedOptions.includes(state.data.value) ? '#333' : 'black' })); }
        } }));
};
exports.default = MultiSelectWithInitialLabel;
